import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/authentication/Login';
import Dashboard from './components/Dashboard';
import '@coreui/coreui/dist/css/coreui.min.css';
import UserDetails from './components/UserDetails';
import ProtectedComp from './components/ProtectedRoute';
import { useState } from 'react';
import ErrorPage from './components/ErrorPage';

function App() {
  const [token, setToken] = useState('')
  const localStorageToken = localStorage.getItem('user_email')
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login setToken={setToken} />} />
          <Route path="/" element={
            <ProtectedComp token={localStorageToken ? localStorageToken : token}>
              <Dashboard />
            </ProtectedComp>
          } />
          <Route path="/userDetails" element={
            <ProtectedComp token={localStorageToken ? localStorageToken : token}>
              <UserDetails />
            </ProtectedComp>
          } />
          <Route path="/*" element={<ErrorPage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
