import React, { useState } from 'react'
import { auth } from '../../firebase'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updatePassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { CContainer, CFormInput, CButton, CSpinner, CImage, CAvatar } from '@coreui/react'

function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isVisibleLoginForm, setIsVisibleLoginForm] = useState(true)

    const signup = (event) => {
        event.preventDefault();
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log(user);
                alert('SignUp Successfully!!!')
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    }

    const signin = async (event) => {
        event.preventDefault();
        setLoading(true)
        await signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                localStorage.setItem('user_email', user.email)
                setLoading(false)
                props.setToken(user.email);
                navigate('/')
                // alert('SignIn Successfully!!!')
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setLoading(false)
            });
    }

    const forgetPassword = async (event) => {
        event.preventDefault();
        // ChangeInputForm();
        console.log("----+++++---", auth.currentUser.email, password);
        const res = await updatePassword(auth.currentUser, password);
        console.log(res);
        alert('Forget  Successfully!!!')
    }

    return (
        <div>
            <form onSubmit={signin}>
                <CContainer fluid style={{ padding: '0px' }}>
                    <div style={{ height: '100vh', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CContainer sm style={{ padding: '30px' }}>
                                <p className='App-color ff' style={{ margin: '10px 0px', textAlign: 'left', fontSize: '25px', }} >
                                    {!isVisibleLoginForm ? 'Change Password' : 'Login'}
                                </p>

                                <CFormInput
                                    type='email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='Enter email'
                                    // value={email}
                                    required
                                />
                                <br></br>
                                <CFormInput
                                    type='password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder='Enter Password'
                                    // value={password}
                                    required
                                />

                                {/* {!isVisibleLoginForm && <div>
                                    <br></br>
                                    <CFormInput
                                        type='password'
                                        // onChange={(e) => setEmail(e.target.value)}
                                        placeholder='Confirm password'
                                        value={email}
                                    // required
                                    />
                                </div>} */}

                                {/* <div className='align-right m-tb-10' >
                                    <p
                                        style={{ display: 'inline' }}
                                        className={'App-color cursor '}
                                        onClick={() => ChangeInputForm()}
                                    >
                                        {isVisibleLoginForm ? 'Forget Password?' : 'Login'}
                                    </p>
                                </div> */}
                                <br></br>
                                <CButton
                                    style={{ width: '100%', backgroundColor: '#a95431', border: '1px solid #a95431' }}
                                    shape="rounded-pill"
                                    type='submit'
                                >
                                    {loading && <CSpinner color="light" size="sm" />} {loading ? <span className='ff'>&nbsp; Loading...</span> : <span className='ff'>Login</span>}
                                </CButton>

                                {/* {isVisibleLoginForm ?
                                    <CButton
                                        style={{ width: '100%', backgroundColor: '#a95431', border: '1px solid #a95431' }}
                                        shape="rounded-pill"
                                        type='submit'
                                    >
                                        {loading && <CSpinner color="light" size="sm" />} {loading ? <span className='ff'>&nbsp; Loading...</span> : <span className='ff'>Login</span>}
                                    </CButton> :
                                    <CButton
                                        style={{ width: '100%', backgroundColor: '#a95431', border: '1px solid #a95431' }}
                                        shape="rounded-pill"
                                        type='submit'
                                    >
                                        {loading && <CSpinner color="light" size="sm" />} {loading ? <span className='ff'>&nbsp; Loading...</span> : <span className='ff' onClick={show}>Change Password</span>}
                                    </CButton>
                                } */}
                            </CContainer>
                        </div>

                        <div className='w-50'>
                            <div className='h-100 w-100 display-flex justify-center align-center' style={{
                                // backgroundImage: `url("https://dashboard.upjao.ai/assets/images/signin.jpg")`,
                                backgroundColor: 'lightgray',
                                backgroundSize: '100%',
                                // backgroundRepeat: 'no-repeat'
                            }}>
                                <div><CAvatar className='w-50' src='https://upjao.ai/wp-content/themes/UpjaoTheme/1x/logo.png' /></div>
                            </div>
                        </div>
                    </div>
                </CContainer>
            </form>
        </div>
    )
}

export default Login