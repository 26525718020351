import { CContainer, CFormInput } from "@coreui/react";
import { cilArrowLeft } from '@coreui/icons';
import CIcon from "@coreui/icons-react";

const FilterWidget = ({ filterText, onFilter, onBack, heading, isArrowVisible, placeholder }) => (
    <CContainer fluid className="p-0 display-flex justify-between">
        <div>
            <h5>{isArrowVisible && <CIcon className="cursor" onClick={onBack} icon={cilArrowLeft} />} &nbsp; {heading}</h5>
        </div>
        <CFormInput
            id="search"
            type="text"
            placeholder={placeholder}
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
            className="w-20"
        />
    </CContainer>
);

export default FilterWidget;