import { CAvatar, CButton } from '@coreui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase'
import { signOut } from "firebase/auth";

function Header() {
  const navigate = useNavigate();
  const logout = async () => {
    await signOut(auth).then(() => {
      localStorage.clear();
      navigate("/login");
    }).catch((error) => {
      console.error(error);
    });
  }

  return (
    <div className='display-flex justify-between' style={{ backgroundColor: 'lightgrey', padding: '0px' }}>
      <div><CAvatar src='https://upjao.ai/wp-content/themes/UpjaoTheme/1x/logo.png' title='logo' style={{height: '60px',
        width: '100px'}}/>
      </div>
      <div className='display-flex flex-direction-column justify-around m-l-5'>
        <CButton  variant='outline' onClick={logout}>Logout</CButton>
      </div>
    </div>
  )
}

export default Header