import React, { useEffect, useMemo, useState } from 'react'
import { CContainer, CCard, CButton, CSpinner } from '@coreui/react'
import DataTable from 'react-data-table-component';
import FilterWidget from '../widgets/FilterWidget';
import customTableStyles from '../constants';
import Header from './header/Header';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../firebase'
import Fuse from 'fuse.js'

function Dashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [userProfileData, setUserProfileData] = useState([]);
  const [userProfileFilteredData, setUserProfileFilteredData] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(!loading)
      await getDocs(collection(db, "dashboard_users"))
        .then((querySnapshot) => {
          const user = querySnapshot.docs.map((doc) => (
            { ...doc.data() })).filter(user => user.email === localStorage.getItem('user_email')
            );
          if (!user) { navigate('/') }
          else setUser(user);
        })
    })()
  }, [])

  useEffect(() => {
    // console.log("user", user);
    // user && console.log(user[0].company);
    (async () => {
      const collectionRef = collection(db, "user_profile_data_prod")
      if (user) {
        const queryDB = query(collectionRef, where("company", "==", user[0].company))
        await getDocs(queryDB).then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => (
            { ...doc.data() }
          ))
          setUserProfileData(data);
          setUserProfileFilteredData(data);
          setLoading(!loading)
        })
      }
    })()
  }, [user])

  useEffect(() => {
    const fuse = new Fuse(userProfileData, {
      keys: ['User Name', 'userID', 'Email Id', 'Phone Number'],
      includeScore: true
    })
    const filterFuse = fuse.search(filterText)
    const updatedData = filterText ? filterFuse.map(res => res.item) : userProfileData;

    // const updatedData = userProfileData.filter(
    //   item => item['User Name'] && item['User Name'].toLowerCase().includes(filterText.toLowerCase()),
    // )

    setUserProfileFilteredData(updatedData)

  }, [filterText])


  const columns = [
    {
      name: 'User Name',
      selector: row => row['User Name'] ? row['User Name'] : '-',
      sortable: true,
    },
    {
      name: 'User ID',
      selector: row => row.userID ? row.userID : '-',
      sortable: true,
    },
    {
      name: 'Email/Phone',
      selector: row => row['Phone Number'] ? row['Phone Number'] : row['Email Id'],
      sortable: true,
    },
    {
      name: "View Scans",
      button: true,
      cell: (row) => (
        <CButton shape="rounded-pill" variant='outline' color='success' size='sm' style={{ margin: '2px 0px', padding: '0px 5px' }}
          onClick={() => navigate('/userDetails', { state: { userData: row } })}
        >
          View
        </CButton>
      ),
    },
  ];

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterWidget
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        heading='All Users'
        onBack={() => navigate('/')}
        placeholder='Search'
      />
    );
  }, [filterText, resetPaginationToggle]);


  return (
    <>
      <CContainer fluid className='p-0'>
        <Header />
        <div className='m-a-10'>
          <CCard>
            <DataTable
              pagination
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
              paginationResetDefaultPage={resetPaginationToggle}
              highlightOnHover
              dense
              theme="solarized"
              // onRowClicked={actionOnRowClick}
              subHeader
              subHeaderComponent={subHeaderComponent}
              style={{ margin: '50px !important' }}
              columns={columns}
              data={userProfileFilteredData}
              customStyles={customTableStyles}
              progressPending={loading}
              progressComponent={<CSpinner className='m-tb-10' color='primary' />}
            />
          </CCard>
        </div>

      </CContainer>
    </>
  )
}

export default Dashboard