import { CAvatar, CContainer } from '@coreui/react'
import React from 'react'

function ErrorPage() {
    return (
        <div>
            <CContainer fluid style={{ padding: '0px' }}>
                <div style={{ height: '100vh', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CContainer sm style={{ padding: '30px' }}>
                            <h1>404</h1>
                            <h1>Page Not Found</h1>
                        </CContainer>
                    </div>

                    <div className='w-50'>
                        <div className='h-100 w-100 display-flex justify-center align-center' style={{
                            backgroundColor: 'lightgray',
                            backgroundSize: '100%',
                        }}>
                            <div><CAvatar className='w-50' src='https://upjao.ai/wp-content/themes/UpjaoTheme/1x/logo.png' /></div>
                        </div>
                    </div>
                </div>
            </CContainer>
        </div>
    )
}

export default ErrorPage