import { CCard, CSpinner } from '@coreui/react';
import DataTable from 'react-data-table-component';
import customTableStyles from '../constants';

const DataTableWidget = ({ columns, data, subHeaderComponent, paginationResetDefaultPage, progressPending, actionOnRowClick }) => (
    <CCard key={data}>
        <DataTable
            key={data}
            pagination
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            paginationResetDefaultPage={paginationResetDefaultPage}
            highlightOnHover
            dense
            theme="solarized"
            onRowClicked={actionOnRowClick}
            subHeader
            subHeaderComponent={subHeaderComponent}
            style={{ margin: '50px !important' }}
            columns={columns}
            data={data}
            customStyles={customTableStyles}
            progressPending={progressPending}
            progressComponent={<CSpinner className='m-tb-10' color='primary' />}
        />
    </CCard>
);

export default DataTableWidget;