import { CButton, CContainer, CSpinner } from '@coreui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from './header/Header';
import DataTableWidget from '../widgets/DataTableWidget';
import FilterWidget from '../widgets/FilterWidget';
import { db } from '../firebase'
import { collection, getDocs, query, where } from "firebase/firestore";
import CIcon from '@coreui/icons-react';
import { cilSave } from '@coreui/icons';
import MessageModal from '../widgets/MessageModel';
import axios, * as others from 'axios';

function UserDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [scanResults, setScanResults] = useState([]);
  const [scanFilteredResults, setScanFilteredResults] = useState([]);
  const [isViewLocationLoading, setIsViewLocationLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const collectionRef = collection(db, "scan_results_prod")
      const userPhoneNo = location.state.userData['Phone Number'];
      const userEmailId = location.state.userData['Email Id']
      const queryDB = query(collectionRef, where('ph_no', "==", userPhoneNo ? userPhoneNo : userEmailId))
      await getDocs(queryDB).then((querySnapshot) => {
        const scanData = querySnapshot.docs.map((doc) => ({ ...doc.data(), isLocationAvailable: false }));
        setScanResults(scanData);
        setScanFilteredResults(scanData)
        setLoading(!loading)
      })
    })()
  }, [])

  useEffect(() => {
    setScanFilteredResults(scanResults.filter(
      item => item.scanID && item.scanID.toLowerCase().includes(filterText.toLowerCase()),
    ))
  }, [filterText])

  const columns = [
    {
      name: 'Crop',
      selector: row => row.crop ? row.crop : '-',
      sortable: true,
    },
    {
      name: 'Scan ID',
      selector: row => row.scanID ? row.scanID : '-',
      sortable: true,
    },
    // {
    //   name: 'Email/Phone',
    //   selector: row => row.ph_no ? row.ph_no : '-',
    //   sortable: true,
    // },
    {
      name: 'Machine',
      selector: row => row.machine ? row.machine : '-',
      sortable: true,
    },
    {
      name: 'Overall Score',
      selector: row => row.count ? `${row.count} %` : '-',
      sortable: true,
    },
    {
      name: 'Moisture',
      selector: row => row.moisture ? row.moisture : '-',
      sortable: true,
    },
    {
      name: 'Location',
      width: '25rem',
      selector: row => row.user_location ? row.user_location :
        (row.isLocationAvailable ? row.userLocation : <CButton onClick={() => viewLoaction(row.latitude, row.longitude, row.scanID)}
          color='secondary' shape="rounded-pill" variant='outline' size='sm' className='m-tb-2' style={{ padding: '0px 5px' }}
        >View</CButton>),
    },
    {
      name: 'Time',
      width: '10rem',
      selector: row => row.timestamp ? new Date(row.timestamp).toLocaleString('en-US') : '-',
      sortable: true,
    },
    {
      name: "PDF",
      button: true,
      cell: (row) => (
        <CIcon icon={cilSave} onClick={() => window.open(row.pdfUrl)} />
      ),
    },
  ];


  const viewLoaction = async (lat, long, scanID) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`)
      const location = response.data.display_name;
      const rowIndex = scanFilteredResults.findIndex(element => scanID === element.scanID)
      const rowData = scanFilteredResults[rowIndex]
      rowData.isLocationAvailable = true
      rowData.userLocation = location
      scanFilteredResults[rowIndex] = rowData
      setScanFilteredResults(scanFilteredResults)
      setIsViewLocationLoading(!isViewLocationLoading)
    } catch (error) {
      console.log(error);
    }
  }

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterWidget
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        heading='User data'
        onBack={() => navigate('/')}
        isArrowVisible={true}
        placeholder='Search By Scan ID'
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (

    <CContainer fluid className='p-0'>
      {/* <MessageModal
        isVisible={isViewLocationLoading}
        title='Location'
        message={''}
        yesButton='OK'
        handleClose={() => setIsViewLocationLoading(false)}
        handleTrigger={() => setIsViewLocationLoading(!isViewLocationLoading)}
      /> */}

      <Header />
      <div className='m-a-10' key={scanFilteredResults}>
        <DataTableWidget
          data={scanFilteredResults}
          columns={columns}
          paginationResetDefaultPage={resetPaginationToggle}
          subHeaderComponent={subHeaderComponent}
          progressPending={loading}
          actionOnRowClick={() => setIsViewLocationLoading(!isViewLocationLoading)}
        />
      </div>
    </CContainer>
  )
}

export default UserDetails